
import Avatar from "@/components/Avatar.vue";
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import ConfirmStudentModal from "@/components/ConfirmStudentModal.vue";
import DeleteCourseModal from "@/components/DeleteCourseModal.vue";
import PublishCourseModal from "@/components/PublishCourseModal.vue";
import EndCourseModal from "@/components/EndCourseModal.vue";
import { isSameOrAfterToday } from "@/plugins/moment";
import { RoutesNames } from "@/router";
import * as Api from "@/types/graphql";
import {
  canUserRequestSubscription,
  CourseStatus,
  CourseStudentStatus,
  getCourseDuration,
  getCourseTypeLabel,
  getStudentStatus,
  getStudentStatusColorClass,
  getStudentStatusLabel,
  isFirstLessonGone,
  userIsSubscribed,
} from "@/utils/courses";
import { isAdmin } from "@/utils/roles";
import _ from "lodash";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: {
    Card,
    Avatar,
    Btn,
    EndCourseModal,
    DeleteCourseModal,
    ConfirmStudentModal,
    PublishCourseModal,
  },
  watch: {
    courses(val, prev) {
      this.search = "";
      if (this.hideStartedNotSubscribed) {
        this.coursesShown = val.filter((course) => {
          if (course.lessons[0]) {
            if (
              !userIsSubscribed(course, this.user.guid) &&
              isFirstLessonGone(course)
            )
              return false;
          }
          return true;
        });
      } else {
        this.coursesShown = val;
      }
    },
    search(val) {
      this.filterCourses();
    },
    filters: {
      handler(val) {
        this.filterCourses();
      },
      deep: true,
    },
  },
})
export default class CoursesTable extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.education.courses) courses;
  @Action insertEducationStudent;

  loading = false;

  @Mutation removeCourse;

  @Prop({ default: false })
  showFilters: boolean;

  @Prop({ default: false })
  endedCourses: boolean;

  @Prop({ default: false })
  hideStartedNotSubscribed;

  courseStatus = CourseStatus;

  courseToEdit = null;
  modalKey = 1;
  showEndCourseModal = null;
  showDeleteCourseModal = null;
  showPublishCourseModal = null;

  newStatus = null;
  showStatusModal = null;

  coursesShown = [];

  search = "";
  filters = {};

  headers = [
    {
      text: "Nome",
      align: "start",
      sortable: true,
      value: "title",
    },
    {
      text: "Prossima lezione",
      align: "center",
      sortable: false,
      value: "next_lesson",
    },
    {
      text: "Iscrizione",
      align: "center",
      sortable: false,
      value: "status",
    },
    {
      text: "Tipologia",
      align: "center",
      sortable: false,
      value: "type",
    },
    {
      text: "Area Tematica",
      align: "center",
      sortable: false,
      value: "area",
    },
    {
      text: "Azioni",
      value: "actions",
      sortable: false,
      filterable: false,
    },
  ];
  selected = [];

  mounted() {
    if (this.isCurrentUserAdmin()) {
      this.headers = [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "title",
        },
        {
          text: "Prossima lezione",
          align: "center",
          sortable: false,
          value: "next_lesson",
        },
        {
          text: "Tipologia",
          align: "center",
          sortable: false,
          value: "type",
        },
        {
          text: "Minimo Partecipanti",
          align: "center",
          sortable: false,
          value: "min_partecipants",
        },
        {
          text: "Iscritti Effettivi",
          align: "center",
          sortable: false,
          value: "partecipants",
        },
        {
          text: "Azioni",
          value: "actions",
          sortable: false,
          filterable: false,
        },
      ];
    }

    if (this.endedCourses) {
      this.headers = this.headers.filter((h) => h.value != "next_lesson");
    }
  }

  isCurrentUserAdmin() {
    return isAdmin(this.user);
  }

  handleClick(course) {
    if (
      course.course_status != CourseStatus.Deleted &&
      course.course_status != CourseStatus.Ended
    ) {
      this.$router.push({
        name: RoutesNames.coursesDetails,
        params: { courseGuid: course.course_guid },
        query: { tab: "courseInfoTab" },
      });
    }
  }

  capitalize(text) {
    return _.capitalize(text);
  }

  filterCourses() {
    let textFilteredCourses = this.courses;
    if (!!this.search && this.search.length > 0) {
      textFilteredCourses = this.courses.filter((c) => {
        if (c.course_title.toLowerCase().includes(this.search.toLowerCase())) {
          return true;
        }
        return false;
      });
    }

    this.coursesShown = _.intersectionBy(textFilteredCourses, "course_guid");
  }

  getPlaceLabel(value) {
    return getCourseTypeLabel(value);
  }

  getNextLesson(course) {
    if (_.isEmpty(course.lessons)) {
      return "Lezioni non presenti";
    } else {
      const text = course.lessons
        .map((lesson) => {
          if (isSameOrAfterToday(lesson.lesson_date)) {
            return (
              moment(lesson.lesson_date, "YYYY-MM-DD").format("DD MMM") +
              " - " +
              lesson.lesson_from.slice(0, -3)
            );
          }
        })
        .filter((item) => {
          return item;
        })[0];
      return text;
    }
  }

  startEndingCourse(course) {
    this.courseToEdit = course;
    this.showEndCourseModal = true;
    this.modalKey = Math.random();
  }

  startDeletingCourse(course) {
    this.courseToEdit = course;
    this.showDeleteCourseModal = true;
    this.modalKey = Math.random();
  }

  startPublishingCourse(course) {
    this.courseToEdit = course;
    this.showPublishCourseModal = true;
    this.modalKey = Math.random();
  }

  removeCourseFromList(course) {
    this.removeCourse(course);
  }

  getStatusLabel(course) {
    return getStudentStatusLabel(course, this.user.guid);
  }

  getStatusColor(course) {
    return getStudentStatusColorClass(
      getStudentStatus(course, this.user.guid) as CourseStudentStatus
    );
  }

  userIsSubscribed(course) {
    return userIsSubscribed(course, this.user.guid);
  }
  canUserRequestSubscription(course) {
    return canUserRequestSubscription(course, this.user.guid);
  }

  showUserStatusModal(course) {
    this.courseToEdit = course;
    this.newStatus = CourseStudentStatus.waiting;
    this.modalKey = Math.random();
    this.showStatusModal = true;
  }

  async updateStatus(status) {
    await this.insertEducationStudent({
      courseGuid: this.courseToEdit.course_guid,
      userGuid: this.user.guid,
    });
    this.modalKey = Math.random();
    this.showStatusModal = false;

    this.$emit("update");
  }

  getStudentsCount(course) {
    return course.students.filter((student) => {
      return student.course_student_status == CourseStudentStatus.subscribed;
    }).length;
  }

  getCourseLessonAndHours(course) {
    if (course.lessons.length > 0) {
      return (
        "LEZIONI: " +
        course.lessons.length +
        " - ORE: " +
        course.course_hours
      );
    } else {
      return (
        "LEZIONI: " + course.course_lessons + " - ORE: " + course.course_hours
      );
    }
  }
}


import Avatar from "@/components/Avatar.vue";
import Modal from "@/components/Modal.vue";
import * as Api from "@/types/graphql";
import { CourseStudentStatus } from "@/utils/courses";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action, Mutation } from "vuex-class";

@Component({
  components: {
    Modal,
    Avatar,
  },
})
export default class ConfirmStudentModal extends Vue {
  @Mutation showSuccess;
  @Mutation showError;
  @Action updateEducationStudentStatus;
  @Prop()
  user: Api.User;
  @Prop()
  course: Api.EducationCourse;
  @Prop()
  newStatus: CourseStudentStatus;
  @Prop({ default: false })
  currentUser: boolean;

  loading = false;

  async confirm() {
    if (this.newStatus == CourseStudentStatus.waiting) {
      this.$emit("success", this.newStatus);
    } else {
      this.loading = true;
      const res = await this.updateEducationStudentStatus({
        courseGuid: this.course.course_guid,
        userGuid: this.user.guid,
        status: this.newStatus,
      });
      this.loading = false;
      if (res) {
        this.$emit("success", this.newStatus);
        this.showSuccessMessage();
        this.onClose();
      } else {
        this.showError("Errore gestione iscrizione");
      }
    }
  }

  getActionText() {
    switch (this.newStatus) {
      case CourseStudentStatus.waiting:
        if (this.currentUser) {
          return "Vuoi iscriverti a questo corso?";
        } else {
          return "Vuoi richiedere l'iscrizione a questo corso?";
        }
      case CourseStudentStatus.abandoned:
        return "Vuoi abbandonare questo corso?";
      case CourseStudentStatus.subscribed:
        return "Vuoi confermare l'iscrizione di questo utente?";
      case CourseStudentStatus.revoked:
        return "Vuoi revocare l'iscrizione di questo utente?";
      case CourseStudentStatus.denied:
        return "Vuoi rifiutare la richiesta d'iscrizione di questo utente?";
    }
  }

  showSuccessMessage() {
    switch (this.newStatus) {
      case CourseStudentStatus.subscribed:
        this.showSuccess("Iscrizione confermata");
        break;
      case CourseStudentStatus.abandoned:
        this.showSuccess("Corso abbandonato");
        break;
      case CourseStudentStatus.revoked:
        this.showSuccess("Iscrizione revocata");
        break;
    }
  }

  getTitle() {
    switch (this.newStatus) {
      case CourseStudentStatus.waiting:
        return "Richiedi Iscrizione";
      case CourseStudentStatus.abandoned:
        return "Abbandona Corso";
      case CourseStudentStatus.subscribed:
        return "Conferma Iscrizione";
      case CourseStudentStatus.revoked:
        return "Revoca Iscrizione";
      case CourseStudentStatus.denied:
        return "Rifiuta Richiesta Iscrizione";
    }
  }

  showUser() {
    return (
      this.newStatus != CourseStudentStatus.waiting &&
      this.newStatus != CourseStudentStatus.abandoned &&
      !this.currentUser
    );
  }

  onClose() {
    this.$emit("close");
  }
}


import * as Api from "@/types/graphql";
import { Component, Vue } from "vue-property-decorator";
import Card from "@/components/Card.vue";
import CoursesTable from "@/components/CoursesTable.vue";
import { Action, State } from "vuex-class";
import { State as StateStore } from "../store";
import { CourseAvailability, CourseStatus } from "@/utils/courses";

@Component({
  components: { Card, CoursesTable },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
})
export default class CoursesListAdminView extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Action getCourses;

  tabKeys = ["publicCoursesTab", "privateCoursesTab", "endedCoursesList", "deletedCoursesList"];

  async mounted() {
    await this.onChangeTab();
  }

  async onChangeTab() {
    const tabKey = this.$route.query.tab;
    
    switch (this.tabKeys.indexOf(tabKey as string)) {
      case 0: {
        await this.getCourses({
          availability: [CourseAvailability.Public],
          status: [CourseStatus.Published]
        });
        break;
      }
      case 1: {
        await this.getCourses({
          availability: [CourseAvailability.Private],
          status: [CourseStatus.Published]
        });
        break;
      }
       case 2: {
        await this.getCourses({
          availability: [CourseAvailability.Public, CourseAvailability.Private],
          status: [CourseStatus.Ended],
        });
        break;
      }
      case 3: {
        await this.getCourses({
          availability: [CourseAvailability.Public, CourseAvailability.Private],
          status: [CourseStatus.Deleted],
        });
        break;
      }
    }
  }
}

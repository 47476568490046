
import Avatar from "@/components/Avatar.vue";
import Modal from "@/components/Modal.vue";
import * as Api from "@/types/graphql";
import gql from "graphql-tag";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Mutation } from "vuex-class";

@Component({
  components: {
    Modal,
    Avatar,
  },
})
export default class PublishCourseModal extends Vue {
  @Mutation showSuccess;
  @Mutation showError;
  @Prop()
  course: Api.EducationCourse;
  loading = false;

  async confirm() {
    this.loading = true;
    const res = await this.$apollo.mutate({
        mutation: gql`
          mutation updateEducationCourse($courseGuid: ID) {
            updateEducationCourse(course_guid: $courseGuid, course_status : "published")
          }
        `,
        variables: {
          courseGuid: this.course.course_guid,
        },
      });
    this.loading = false;
    if (res) {
      this.$emit("success");
      this.showSuccess('Corso pubblicato');
      this.onClose();
    } else {
      this.showError("Errore pubblicazione corso");
    }
  }

  onClose() {
    this.$emit("close");
  }
}
